import { createStore as reduxCreateStore } from "redux"
import update from 'immutability-helper';
import { Cookies } from 'react-cookie';

let cookies = new Cookies();

const INITIAL_STATE = { 
    isLoggedIn: cookies.get("token") ? true : false,
    //Légyszi ne írjuk most át, ez azért van így, hogy ne haljon le az egész
    user: false,
    count:0
}


const uiReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case IS_LOGGED_IN:
            return update(state, {
                isLoggedIn: { $set: !state.isLoggedIn }
            });
        case SET_USER:
            return update(state, {
                user: { 
                    $apply: (current) => {
                        const userDataString = JSON.stringify(payload.user);
                        localStorage.setItem('user', userDataString);
                        return payload.user;
                    }
                }
            });
        case RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

const IS_LOGGED_IN = 'IS_LOGGED_IN';
const SET_USER = 'SET_USER';

const RESET = 'RESET';

export const getIsLogedIn = (state) => state.isLoggedIn;
export const getUser = (state) => state.user

export const setIsLoggedIn = () => ({
    type: IS_LOGGED_IN, 
    payload: {  }
});

export const setUser = (user) => ({
    type: SET_USER, 
    payload: { user }
});

export const resetUI = () => ({
    type:RESET,
    payload: { }
});


const createStore = () => reduxCreateStore(uiReducer, INITIAL_STATE)
export default createStore
